import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Link, useParams } from 'react-router-dom';
import {
  convertQueryToRxDBQuery,
  getLibraryRxDBQuery,
} from 'shared/filters-compiler/convertQueryToRxDBQuery';
import { useViewBadgeContent } from 'shared/foreground/database/helperHooks';
import { useFaviconUrlFromFeedId, useNameFromFeedId } from 'shared/foreground/stateHooks';
import { addDefaultPropertiesToFilteredView } from 'shared/foreground/stateUpdateHelpers';
import { updateRssFolderIsCollapsed } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import combineClasses from 'shared/foreground/utils/combineClasses';
import getSplitByDefaultValue from 'shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import type { FilteredView, FirstClassDocument, RssFolder, RssItem } from 'shared/types';
import { DocumentLocation, FeedDocumentLocation, SplitByKey } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { getFeedTrueAndFeedItemQueryFromFeedId } from 'shared/utils/feed';
import getFirstEmojiFromString from 'shared/utils/getFirstEmojiFromString';
import removeEmoji from 'shared/utils/removeEmoji';
import { safeDecodeURIComponent } from 'shared/utils/safeDecodeURIComponent';

import { getFilteredViewPath } from '../utils/getFilteredViewPath';
import { useShortcutsMap } from '../utils/shortcuts';
import { draggablePinnedFeedPrefix } from '../utils/sidebar';
import Button from './Button';
import BuiltInCategoryViewDropDown from './Dropdown/BuiltInCategoryViewDropDown';
import FeedSectionDropdown from './Dropdown/FeedSectionDropdown';
import FilterViewDropdown from './Dropdown/FilterViewDropdown';
import RssFolderDropdown from './Dropdown/RssFolderDropdown';
import RssItemDropdown from './Dropdown/RssItemDropdown';
import ChevronDown from './icons/20StrokeChevronDownSmall';
import FeedFallbackFavicon from './icons/FeedFallbackFavicon';
import NavFolderClosedIcon from './icons/NavFolderClosedIcon';
import StrokeGrab from './icons/StrokeGrabIcon';
import { ImageWithFallback } from './ImageWithFallback';
import styles from './NavItem.module.css';
import Tooltip from './Tooltip';

type LinkOrChildrenProps = {
  to?: string;
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkOrChildren = forwardRef<any, LinkOrChildrenProps>(function LinkOrChildren(
  { to, children, ...extraProps },
  ref,
) {
  if (to) {
    return (
      <Link {...extraProps} className={styles.link} to={{ pathname: to }} ref={ref}>
        {children}
      </Link>
    );
  }

  if (!children) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.cloneElement(children as React.ReactElement<any>, { ...extraProps, ref });
});

const ChevronButton = ({
  isCollapsed,
  setIsCollapsed,
}: { isCollapsed: boolean; setIsCollapsed: (isCollapsed: boolean) => void }) => {
  return (
    <Button
      variant="unstyled"
      className={combineClasses([styles.chevronButton, isCollapsed ? styles.isCollapsed : ''])}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <ChevronDown />
    </Button>
  );
};

const hasChildren = (children: React.ReactNode) => {
  if (!children) {
    return false;
  }

  if (Array.isArray(children) && children.length === 0) {
    return false;
  }

  return true;
};

type NavItemProps = {
  className?: string;
  children?: React.ReactNode;
  isActive?: boolean;
  isCollapsed?: boolean;
  isHovered?: boolean;
  itemIsBeingDraggedOver?: boolean;
  left?: JSX.Element;
  name: string | JSX.Element;
  nameClassName?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  provided?: DraggableProvided;
  right?: JSX.Element;
  setIsCollapsed?: (isCollapsed: boolean) => void;
  shortcut?: string | string[] | undefined;
  showGrab?: boolean;
  style?: React.CSSProperties;
  to?: string;
  tooltipContent?: string | JSX.Element;
};

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(function NavItem(
  {
    className = '',
    children,
    isActive = false,
    isCollapsed = true,
    isHovered = false,
    itemIsBeingDraggedOver = false,
    left,
    name,
    nameClassName = '',
    onMouseEnter,
    onMouseLeave,
    provided,
    right,
    setIsCollapsed,
    shortcut,
    showGrab = false,
    style,
    to,
    tooltipContent = '',
    ...extraProps
  },
  ref,
) {
  return (
    <div {...extraProps} className={combineClasses([styles.navItemWrapper, className])} ref={ref}>
      <div
        style={style}
        {...(provided?.draggableProps ?? {})}
        {...(provided?.dragHandleProps ?? {})}
        ref={provided?.innerRef}
      >
        <div
          className={styles.chevronAndItemWrapper}
          onMouseEnter={(e) => {
            if (window.isRadixDropdownOpen || window.isReactDndDragging) {
              return;
            }
            onMouseEnter?.(e);
          }}
          onMouseLeave={(e) => {
            if (window.isRadixDropdownOpen || window.isReactDndDragging) {
              return;
            }
            // Without this timeout the tooltip keeps showing
            setTimeout(() => {
              onMouseLeave?.(e);
            }, 0);
          }}
        >
          {hasChildren(children) && setIsCollapsed && (
            <ChevronButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          )}
          <Tooltip content={tooltipContent} shortcut={shortcut} placement="right">
            <LinkOrChildren to={to}>
              <div
                className={combineClasses(
                  styles.navItem,
                  isActive ? styles.isActive : '',
                  isHovered && !itemIsBeingDraggedOver ? styles.isHovered : '',
                )}
              >
                {showGrab && (
                  <div className={styles.grabIcon}>
                    <StrokeGrab />
                  </div>
                )}
                {left && <div className={styles.left}>{left}</div>}
                <p className={combineClasses([styles.name, nameClassName])}>{name}</p>
                {right && <div className={styles.right}>{right}</div>}
              </div>
            </LinkOrChildren>
          </Tooltip>
        </div>
        {hasChildren(children) && (
          <div className={combineClasses([styles.children, isCollapsed ? styles.isCollapsed : ''])}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
});

export const NavItemWithHover = forwardRef<HTMLDivElement, NavItemProps>(function NavItemWithHover(
  { children, ...props },
  ref,
) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <NavItem
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref}
      isHovered={isHovered}
      {...props}
    >
      {children}
    </NavItem>
  );
});

type NavItemSeparatorProps = {
  name: string;
  children?: React.ReactNode;
  isCollapsed?: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const NavItemSeparator = ({
  name,
  children,
  isCollapsed = true,
  setIsCollapsed,
}: NavItemSeparatorProps) => {
  return (
    <div className={styles.navItemSeparatorWrapper}>
      <Button
        variant="unstyled"
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={styles.chevronAndItemSeparatorWrapper}
      >
        <p className={styles.name}>{name}</p>
        {hasChildren(children) && (
          <ChevronButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        )}
      </Button>
      {hasChildren(children) && (
        <div className={combineClasses(styles.children, isCollapsed ? styles.isCollapsed : '')}>
          {children}
        </div>
      )}
    </div>
  );
};

type FilteredViewItemProps = Omit<NavItemProps, 'name'> & {
  view: FilteredView;
  type?: 'built-in-category' | 'view' | 'rss-folder' | 'rss-item';
  name?: string;
  shouldRunSidebarItemCounts?: boolean;
  rightComponentIfNotHovered?: JSX.Element;
  rssItem?: RssItem;
};

export const FilteredViewItem = React.memo(function FilteredViewItem({
  view,
  type,
  children,
  rssItem,
  isActive,
  itemIsBeingDraggedOver,
  className = '',
  rightComponentIfNotHovered,
  shouldRunSidebarItemCounts,
  ...extraProps
}: FilteredViewItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const documentLocations = useDocumentLocations();
  const url = getFilteredViewPath(view, documentLocations);
  const badgeContent = useViewBadgeContent({ view, shouldRun: shouldRunSidebarItemCounts });

  const onDropdownOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setIsHovered(false);
    }
  }, []);

  const splitByValue = view.splitBy
    ? getSplitByDefaultValue(view.splitBy, documentLocations) ?? undefined
    : undefined;

  const getViewMangoQueryWithoutSort = useCallback(() => {
    const { mangoQuery } = convertQueryToRxDBQuery<FirstClassDocument>({
      query: view.query,
      splitBy: view.splitBy,
      splitByValue,
    });

    return mangoQuery;
  }, [splitByValue, view.query, view.splitBy]);

  // Hack to keep the dropdown button visible when clicking an item
  // Without this if you click an item it will redirect to that view, React will
  // re-render and the onMouseEnter won't be triggered.
  useEffect(() => {
    if (isActive === undefined) {
      return;
    }

    setIsHovered(isActive);
  }, [isActive]);

  const rightComponent = useMemo(() => {
    if (!isHovered || itemIsBeingDraggedOver) {
      return badgeContent ? (
        <div className={combineClasses([styles.docsCount, isActive ? styles.isActive : ''])}>
          {badgeContent}
        </div>
      ) : undefined;
    }

    if (type === 'view') {
      return (
        <FilterViewDropdown
          view={view}
          onOpenChange={onDropdownOpenChange}
          getViewMangoQueryWithoutSort={getViewMangoQueryWithoutSort}
        />
      );
    }

    if (type === 'built-in-category') {
      return (
        <BuiltInCategoryViewDropDown
          view={view}
          onOpenChange={onDropdownOpenChange}
          getViewMangoQueryWithoutSort={getViewMangoQueryWithoutSort}
        />
      );
    }

    if (type === 'rss-item' && rssItem) {
      return (
        <RssItemDropdown
          rssItem={rssItem}
          onOpenChange={onDropdownOpenChange}
          getViewMangoQueryWithoutSort={getViewMangoQueryWithoutSort}
        />
      );
    }

    if (type === 'rss-folder') {
      return (
        <RssFolderDropdown
          view={view}
          onOpenChange={onDropdownOpenChange}
          getViewMangoQueryWithoutSort={getViewMangoQueryWithoutSort}
        />
      );
    }

    return undefined;
  }, [
    isHovered,
    itemIsBeingDraggedOver,
    badgeContent,
    type,
    view,
    onDropdownOpenChange,
    rssItem,
    getViewMangoQueryWithoutSort,
    isActive,
  ]);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const itemTypeClassName = useMemo(() => {
    if (type === 'rss-folder') {
      return styles.rssFolderItem;
    }

    if (type === 'rss-item') {
      return styles.rssItem;
    }

    if (type === 'built-in-category') {
      return styles.libraryItem;
    }

    return styles.viewItem;
  }, [type]);

  const name = useMemo(() => {
    const viewName = view.name;
    const emoji = getFirstEmojiFromString(viewName);
    const nameWithoutEmoji = removeEmoji({ input: viewName, emojiToRemove: emoji });

    if (type === 'rss-folder') {
      return nameWithoutEmoji;
    }
    if (type !== 'view') {
      return viewName;
    }

    if (!emoji) {
      return viewName;
    }

    return (
      <span>
        <span className={styles.emojiName}>{emoji}</span>
        {nameWithoutEmoji}
      </span>
    );
  }, [view.name, type]);

  return (
    <NavItem
      name={name}
      className={combineClasses([itemTypeClassName, className])}
      to={url}
      right={rightComponent}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isActive={isActive}
      itemIsBeingDraggedOver={itemIsBeingDraggedOver}
      isHovered={isHovered}
      {...extraProps}
    >
      {children}
    </NavItem>
  );
});

type FeedItemProps = Omit<NavItemProps, 'name'> & {
  feedItem: RssItem;
  shouldRunSidebarItemCounts?: boolean;
};

export const FeedItem = React.memo(function FeedItem({
  feedItem,
  shouldRunSidebarItemCounts,
  ...extraProps
}: FeedItemProps) {
  const imageUrl = useFaviconUrlFromFeedId(feedItem.rssSourceId);
  const rssName = useNameFromFeedId(feedItem.rssSourceId);

  const view = addDefaultPropertiesToFilteredView({
    view: {
      name: rssName ?? 'No name',
      query: getFeedTrueAndFeedItemQueryFromFeedId(feedItem.rssSourceId),
      splitBy: SplitByKey.Seen,
      showCountBadge: feedItem.showCountBadge,
    },
    pathname: '',
  });

  return (
    <FilteredViewItem
      left={
        <ImageWithFallback
          className={styles.feedImage}
          imageUrl={imageUrl}
          fallbackImage={
            <span className={styles.feedImageFallback}>
              <FeedFallbackFavicon />
            </span>
          }
        />
      }
      type="rss-item"
      view={view}
      rssItem={feedItem}
      shouldRunSidebarItemCounts={shouldRunSidebarItemCounts}
      {...extraProps}
    />
  );
});

type FeedSectionItemProps = Omit<NavItemProps, 'name'>;

export const FeedSectionItem = ({ children, ...extraProps }: FeedSectionItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const onDropdownOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setIsHovered(false);
    }
  }, []);

  const getViewMangoQueryWithoutSort = useCallback(() => {
    const { mangoQuery } = getLibraryRxDBQuery({
      filterByDocumentLocation: DocumentLocation.Feed,
      filterByFeedDocumentLocation: FeedDocumentLocation.New,
    });

    return mangoQuery;
  }, []);

  const rightComponent = useMemo(() => {
    if (!isHovered) {
      return undefined;
    }

    return (
      <FeedSectionDropdown
        getViewMangoQueryWithoutSort={getViewMangoQueryWithoutSort}
        onOpenChange={onDropdownOpenChange}
      />
    );
  }, [getViewMangoQueryWithoutSort, isHovered, onDropdownOpenChange]);

  return (
    <NavItem
      name="Feed"
      to="/feed"
      right={rightComponent}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHovered={isHovered}
      {...extraProps}
    >
      {children}
    </NavItem>
  );
};

type DraggableRssFolderItemProps = Omit<NavItemProps, 'name'> & {
  rssFolder: RssFolder;
  pathname: string;
  rssFolderFilteredView?: FilteredView;
  itemIsBeingDraggedOver?: boolean;
  savedViewId?: string;
  shouldRunSidebarItemCounts?: boolean;
  index: number;
};

export const DraggableRssFolderItem = React.memo(function DraggableRssFolderItem({
  rssFolder,
  pathname,
  rssFolderFilteredView,
  index,
  isActive,
  itemIsBeingDraggedOver,
  savedViewId,
  shouldRunSidebarItemCounts,
  ...extraProps
}: DraggableRssFolderItemProps) {
  const { rssFolderFilteredViewId } = useParams<{ rssFolderFilteredViewId?: string }>();
  const isEmptyFolderActive = rssFolderFilteredViewId === rssFolder.filteredViewId;
  const isCollapsed = rssFolder.isCollapsedInSidebar;
  const childrenRssItems = rssFolder.childrenRssItems;

  const className = useMemo(() => {
    if (!itemIsBeingDraggedOver) {
      return '';
    }

    if (isCollapsed || !childrenRssItems.length) {
      return styles.itemIsBeingDraggedOver;
    }

    return `${styles.itemIsBeingDraggedOver} ${styles.itemIsBeingDraggedOverExpanded}`;
  }, [isCollapsed, itemIsBeingDraggedOver, childrenRssItems.length]);

  const setIsCollapsed = useCallback(
    (isCollapsed: boolean) => {
      updateRssFolderIsCollapsed(rssFolder.id, isCollapsed, { userInteraction: 'click' });
    },
    [rssFolder],
  );

  if (!rssFolderFilteredView) {
    return null;
  }

  const folderEmoji = getFirstEmojiFromString(rssFolderFilteredView.name);

  return (
    <FilteredViewItem
      left={
        folderEmoji ? <span>{folderEmoji}</span> : <NavFolderClosedIcon className={styles.folderIcon} />
      }
      view={rssFolderFilteredView}
      isActive={isEmptyFolderActive || isActive}
      type="rss-folder"
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      className={className}
      itemIsBeingDraggedOver={itemIsBeingDraggedOver}
      shouldRunSidebarItemCounts={shouldRunSidebarItemCounts}
      {...extraProps}
    >
      {childrenRssItems.map((feedItem) => {
        return (
          <FeedItem
            key={feedItem.id}
            feedItem={feedItem}
            isActive={safeDecodeURIComponent(pathname).startsWith(
              `/filter/${getFeedTrueAndFeedItemQueryFromFeedId(feedItem.rssSourceId)}`,
            )}
            shouldRunSidebarItemCounts={shouldRunSidebarItemCounts && !isCollapsed}
          />
        );
      })}
    </FilteredViewItem>
  );
});

// Separating the list from the draggable component to avoid re-rendering the whole list
// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
type PinnedRssItemsListProps = {
  pinnedRssItems: RssItem[];
  pathname: string;
  shouldRunSidebarItemCounts: boolean;
};

export const PinnedRssItemsList = React.memo(function PinnedRssItemsList({
  pinnedRssItems,
  pathname,
  shouldRunSidebarItemCounts,
}: PinnedRssItemsListProps) {
  return (
    <>
      {pinnedRssItems.map((feedItem, index) => (
        <Draggable
          key={`${draggablePinnedFeedPrefix}${feedItem.id}`}
          draggableId={`${draggablePinnedFeedPrefix}${feedItem.id}`}
          index={index}
        >
          {(provided) => {
            return (
              <FeedItem
                key={feedItem.id}
                feedItem={feedItem}
                isActive={decodeURIComponent(pathname).startsWith(
                  `/filter/${getFeedTrueAndFeedItemQueryFromFeedId(feedItem.rssSourceId)}`,
                )}
                provided={provided}
                style={provided.draggableProps.style}
                shouldRunSidebarItemCounts={shouldRunSidebarItemCounts}
                showGrab
              />
            );
          }}
        </Draggable>
      ))}
    </>
  );
});

type PinnedViewsItemsListProps = {
  restOfPinnedViews: FilteredView[];
  savedViewId?: string;
  shouldRunSidebarItemCounts: boolean;
};

export const PinnedViewsItemsList = React.memo(function PinnedViewsItemsList({
  restOfPinnedViews,
  savedViewId,
  shouldRunSidebarItemCounts,
}: PinnedViewsItemsListProps) {
  const shortcutsMap = useShortcutsMap();

  return (
    <>
      {restOfPinnedViews.map((view, index) => (
        <Draggable key={view.id} draggableId={view.id} index={index}>
          {(provided) => {
            const shortcutNumber = index + 4;
            const shortcut =
              shortcutNumber < 10 ? shortcutsMap[ShortcutId[`GoToPinnedView${index + 1}`]] : '';

            return (
              <FilteredViewItem
                key={view.id}
                view={view}
                isActive={savedViewId === view.id}
                provided={provided}
                style={provided.draggableProps.style}
                shortcut={shortcut}
                type="view"
                shouldRunSidebarItemCounts={shouldRunSidebarItemCounts}
                showGrab
              />
            );
          }}
        </Draggable>
      ))}
    </>
  );
});
