import React, { useCallback, useMemo } from 'react';
import { globalState } from 'shared/foreground/models';
import { getView } from 'shared/foreground/stateGetters';

import { Dialog } from './Dialog';

export const DeleteViewDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void }) => {
  const filteredViewIdToDelete = globalState(useCallback((state) => state.filteredViewIdToDelete, []));
  const filteredView = useMemo(() => getView(filteredViewIdToDelete), [filteredViewIdToDelete]);

  if (!isOpen || !filteredView) {
    return null;
  }

  const isRssFolder = Boolean(filteredView?.rssFolderId);

  return (
    <div>
      <Dialog
        id="delete-view"
        title={isRssFolder ? 'Delete folder?' : 'Permanently delete this filtered view?'}
        subtitle={
          isRssFolder
            ? 'Are you sure you want to delete this folder?'
            : 'If you click Delete, none of your documents will be deleted, but the filter will be permanently removed and need to be manually recreated.'
        }
        actionTitle="Delete"
        cancelTitle="Cancel"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel}
      />
    </div>
  );
};
